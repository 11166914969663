export const contactInfoFormDataInitialState = {
    "firstName": "",
    "middleName": "",
    "lastName": "",
    "email": "",
    "phoneHome": "",
    "phoneOffice": "",
    "address": "",
    "state": "",
    "country": "",
    "city": "",
    "zipCode": "",
    "referredBy": "",
    "comments": ""
}

export const propertyInfoFormDataInitialState = {
    "residenceType": "",
    "noOfUnits": "",
    "address": "",
    "state": "",
    "city": "",
    "zipCode": "",
    "LITerms": "",
    "desiredRate": "",
    "loanPurpose": "",
    "loanAmount": "",
    "yearAcquired": "",
    "originalCost": "",
    "currentPropertyValue": "",
    "purposeOfRefinance": "",
    "amountExistingItems": "",
    "currentPropertyValue": "",
    "improvementLoan": ""
}


export const incomeInfoFormDataInitialState = {
    "incomePeriodType": "",
    "baseIncome": "",
    "overTime": "",
    "bonuses": "",
    "commissions": "",
    "interest": "",
    "netRentalIncome": "",
    "otherIncome": ""
}

export const steps = ["Contact Info", "Loan/Property", "Income"]

export const contactInfoAbbreviation = {
    "firstName": "first name",
    "middleName": "middle name",
    "lastName": "last name",
    "address": "address",
    "city": "city",
    "state": "state",
    "country": "country",
    "zipCode": "zip code",
    "email": "email",
    "phoneHome": "Phone - Home",
    "phoneOffice": "Phone - Office",
    "referredBy": "referred by",
}

export const loanPropertyAbbreviation = {
    "residenceType": "Residence Type",
    "address": "address",
    "city": "city",
    "state": "State",
    "zipCode": "zip code",
    "LITerms": "Loan Information - Terms",
    "desiredRates": "Desired Rates",
    "loanPurpose": "Loan Purpose",
    "loanAmount": "Loan Amount",
    "yearAcquired": "Year Acquired",
    "originalCost": "Original Cost",
    "currentPropertyValue": "Current Property Value",
    "purposeOfRefinance": "Purpose of refinance",
    "amountExistingItems": "Amount Existing Liens",
}

export const incomeAbbreviation = {
    "incomePeriodType": "Income Period Type",
    "baseIncome": "Base Income",
    "overTime": "Overtime",
    "bonuses": "Bonuses",
    "interest": "Dividends/Interests",
    "commissions": "Commissions",
    "netRentalIncome": "Net Rental Income",
    "otherIncome": "Other Income",
}

export const purchaseFormInitialState = {
    "name": '',
    "email": "",
    "purchasePrice": "",
    "downPayment": "",
    "zipCode": "",
    "quotedCreditScore": "",
    "propertyType": "",
    "amortizationPeriod": "",
    "typeOfLoan": "",
    "closingCostAssistance": false
}

export const refinanceFormInitialState = {
    "name": '',
    "email": "",
    "estimatedValue": "",
    "loanAmountRequired": "",
    "zipCode": "",
    "quotedCreditScore": "",
    "propertyType": "",
    "reasonForRefinance": "",
    "amortizationPeriod": "",
    "typeOfLoan": "",
    "closingCostAssistance": false
}

export const purchaseAbbreviation = {
    "name": "Name",
    "email": "Email",
    "purchasePrice": "Purchase Price",
    "downPayment": "Down Payment",
    "zipCode": "Zip Code",
    "quotedCreditScore": "Quoted Credit Score",
    "propertyType": "Property Type",
    "amortizationPeriod": "Amortization Period",
    "typeOfLoan": "Type Of Loan",
    "closingCostAssistance": "Closing Cost Assistance"
}

export const refinanceAbbreviation = {
    "name": "Name",
    "email": "Email",
    "estimatedValue": "Estimated Value",
    "loanAmountRequested": "Loan Amount Requested",
    "zipCode": "Zip Code",
    "quotedCreditScore": "Quoted Credit Score",
    "propertyType": "Property Type",
    "reasonForRefinance": "Reason For Refinance",
    "amortizationPeriod": "Amortization Period",
    "typeOfLoan": "Type Of Loan",
    "closingCostAssistance": "Closing Cost Assistance"
}