import React from 'react'

export default function ContactInfo(props) {
    const { contactInfoFormData, handleOnChangeContactInfo, backButtonHandle} = props;

    return (<fieldset>
            <h2>Personal information</h2>
            <p class="desc">Please enter your infomation and proceed to next step so we can build your account</p>
            <div class="fieldset-content">
                <div class="form-row">
                    <label class="form-label">Name</label>
                    <div class="form-flex">
                        <div class="form-group">
                            <input type="text" value={contactInfoFormData.firstName}
                            onChange={e => handleOnChangeContactInfo("firstName", e.target.value)} />
                            <span class="text-input">First Name</span>
                        </div>
                        <div class="form-group">
                            <input type="text" value={contactInfoFormData.middleName}
                            onChange={e => handleOnChangeContactInfo("middleName", e.target.value)}  />
                            <span class="text-input">Middle Name</span>
                        </div>
                        <div class="form-group">
                            <input type="text" value={contactInfoFormData.lastName}
                            onChange={e => handleOnChangeContactInfo("lastName", e.target.value)}  />
                            <span class="text-input">Last</span>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-label">Address</label>
                    <input type="text" value={contactInfoFormData.a}
                    onChange={e => handleOnChangeContactInfo("address", e.target.value)}  />
                    <span class="text-input">Street Address</span>
                </div>
                <div class="form-row">
                    <div class="form-flex">
                        <div class="form-group">
                            <input type="text" value={contactInfoFormData.city}
                            onChange={e => handleOnChangeContactInfo("city", e.target.value)}  />
                            <span class="text-input">City</span>
                        </div>
                        <div class="form-group">
                            <input type="text" value={contactInfoFormData.state}
                            onChange={e => handleOnChangeContactInfo("state", e.target.value)}/>
                            <span class="text-input">State</span>
                        </div>
                        <div class="form-group">
                            <input type="text" value={contactInfoFormData.zipCode}
                            onChange={e => handleOnChangeContactInfo("zipCode", e.target.value)} />
                            <span class="text-input">Zip Code</span>
                        </div>
                        <div class="form-group">
                            <input type="text" value={contactInfoFormData.country}
                            onChange={e => handleOnChangeContactInfo("country", e.target.value)} />
                            <span class="text-input">Country</span>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-label">Email</label>
                    <input type="email" value={contactInfoFormData.email}
                    onChange={e => handleOnChangeContactInfo("email", e.target.value)} />
                    <span class="text-input">Example  :<span>  Jeff@gmail.com</span></span>
                </div>

                <div class="form-row">
                    <label class="form-label">Phone</label>
                    <div class="form-flex">
                        <div class="form-group">
                            <input type="text" value={contactInfoFormData.phoneHome}
                            onChange={e => handleOnChangeContactInfo("phoneHome", e.target.value)} />
                            <span class="text-input">Home</span>
                        </div>
                        <div class="form-group">
                            <input type="text" value={contactInfoFormData.phoneOffice}
                            onChange={e => handleOnChangeContactInfo("phoneOffice", e.target.value)}/>
                            <span class="text-input">Office</span>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="email" class="form-label">Referred by</label>
                    <input type="email" value={contactInfoFormData.referredBy}
                    onChange={e => handleOnChangeContactInfo("referredBy", e.target.value)}/>
                    <span class="text-input">Name </span>
                </div>

                {/* <div class="form-group">
                    <label for="email" class="form-label">Comments </label>
                    <textarea value={contactInfoFormData.comments}
                    onChange={e => handleOnChangeContactInfo("comments", e.target.value)} ></textarea>
                    <span class="text-input">Name </span>
                </div> */}
            </div>
        </fieldset>

    )
}
