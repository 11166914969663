import React, { useState } from 'react'
import Purchase from './purchase'
import Refinance from './refinance'
import { navigate } from '@reach/router'
import {
  purchaseFormInitialState,
  refinanceFormInitialState,
  purchaseAbbreviation,
  refinanceAbbreviation
} from './initial-states'

export default function Estimate() {
  const EMAIL_TO = "madhu.chukkapalli@gmail.com"
  const handleFillFormOnClick = () => {
    navigate('/form')
  }

  const [isPurchase, setIsPurchase] = useState(true)
  const [purchaseFormData, setPurchaseFormData] = useState(
    purchaseFormInitialState
  )
  const [refinanceFormData, setRefinanceFormData] = useState(
    refinanceFormInitialState
  )

  const handlePurchaseFormDataChange = (item, value) => {
    const data = { ...purchaseFormData }
    data[item] = value
    setPurchaseFormData(data)
  }

  const handleRefinanceFormDataChange = (item, value) => {
    const data = { ...refinanceFormData }
    data[item] = value
    setRefinanceFormData(data)
  }

  const handleSubmit = () => {
    const NEW_LINE = '%0D%0A'
    let body = isPurchase
      ? 'Purchase Estimate'
      : 'Refinance Estimate' + NEW_LINE

    if (isPurchase) {
      const fields = Object.keys(purchaseAbbreviation)
      for (let key of fields) {
        body +=
          purchaseAbbreviation[key] +
          ': ' +
          (purchaseFormData[key] || '') +
          NEW_LINE
      }
    } else {
      const fields = Object.keys(refinanceAbbreviation)
      for (let key of fields) {
        body +=
          refinanceAbbreviation[key] +
          ': ' +
          (refinanceFormData[key] || '') +
          NEW_LINE
      }
    }
    window.location.href = `mailto:${EMAIL_TO}?subject=Application Form&body=` + body;
  }


  return (
    <div class='main'>
      <header>
        <div class='menu-header'>
          <div class='menu-header-wrapper'>
            <div class='left-logo'>
              <img
                src='upload/logo.png'
                height='50'
                width='50'
                alt=''
                class='logo-img'
              />
            </div>
            <div class='right-menu'>
              <ul>
                <li><a href="/">Home</a></li>
                <li><a href="/#About">About us</a></li>
                <li><a href="/#Services">Services</a></li>
                <li><a href="/estimate">Estimate</a></li>
                <li><a href="/#Testimonials">Testimonials</a></li>
                <li><a onClick={handleFillFormOnClick} class="button button-stripe">Apply Now</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class='wrap'>
          <div class='header-wrapper'>
            <h2>Estimate</h2>
          </div>
        </div>
      </header>
      <div class='container form-container'>
        <div>
          <div className='radio-group purchase-group'>
            <input
              type='radio'
              name='estimateType'
              checked={isPurchase}
              onChange={e => setIsPurchase(true)}
            />{' '}
        Purchase
        <input
              type='radio'
              name='estimateType'
              checked={!isPurchase}
              onChange={e => setIsPurchase(false)}
            />{' '}
        Refinance
      </div>
          {isPurchase ? (
            <Purchase
              purchaseFormData={purchaseFormData}
              handlePurchaseFormDataChange={handlePurchaseFormDataChange}
            />
          ) : (
              <Refinance
                handleRefinanceFormDataChange={handleRefinanceFormDataChange}
                refinanceFormData={refinanceFormData}
              />
            )}
          <button className='button btn-stripe m-10' onClick={handleSubmit}>
            Submit
      </button>
        </div>
      </div>
      <footer>
        <div class='wrap'>
          <p>
            &copy; 2020 <strong>FinMart</strong>, All Rights Reserved
          </p>
        </div>
      </footer>
    </div>
  )
}
