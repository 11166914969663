import React from 'react'
import { navigate } from '@reach/router'

export default function Home() {
    const handleFillFormOnClick = () => {
        navigate('/form')
    }
    return (
        <div class="main">
            <header>
                <div class="menu-header">
                    <div class="menu-header-wrapper">
                        <div class="left-logo">
                            <img src="upload/logo.png" height="50" width="50" alt="" class="logo-img" />
                        </div>
                        <div class="right-menu">
                            <ul>
                                <li><a href="#">Home</a></li>
                                <li><a href="#About">About us</a></li>
                                <li><a href="#Services">Services</a></li>
                                <li><a href="/estimate">Estimate</a></li>
                                <li><a href="#Testimonials">Testimonials</a></li>
                                <li><a onClick={handleFillFormOnClick} class="button button-stripe">Apply Now</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="wrap">
                    {/* <!-- <img src="upload/iphone.png" height="532" width="252" alt="" class="header-img"> --> */}
                    <div class="header-wrapper">
                        <h1>Financial services, with a personal touch </h1>
                        <p>We provide wide range of financial services. We specialize in Life, Health Insurance and Mortgage services.</p>
                        <div class="buttons-wrapper">
                            <a onClick={handleFillFormOnClick}  class="button">Apply Now</a>
                            <a href="#About" class="button">Learn More</a>
                        </div>
                    </div>

                </div>
            </header>
            <div class="spanning">
                <div class="promo clearfix">
                    <div class="wrap">
                        <div class="promo-wrapper clearfix">
                            <div class="promo-column">
                                <img src="upload/pin.png" height="32" width="24" alt="" />
                                <h5>Quick</h5>
                                <p>We provide quick response for all your insurance and mortgage needs.
                            </p>
                            </div>
                            <div class="promo-column">
                                <img src="upload/settings.png" height="32" width="33" alt="" />
                                <h5>Reliable</h5>
                                <p>Our staff includes certified mortgage loan officer with years of maturity. We are certified by CMS to help our customers with their health insurance needs.
                            </p>
                            </div>
                            <div class="promo-column">
                                <img src="upload/easy.png" height="32" width="34" alt="" />
                                <h5>Easy</h5>
                                <p>Our customer can provide all the required information from our portal with one click</p>
                            </div>
                            <div class="promo-column">
                                <img src="upload/saturn.png" height="32" width="32" alt="" />
                                <h5>Comprehensive Solutions</h5>
                                <p>We provide comprehensive solutions for economic protection, kids education, retirement and transfer of wealth.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="discover clearfix" id="About">
                    <div class="wrap">
                        <div class="discover-content clearfix">
                            <h2>About Us</h2>
                            <p>FinMart has been providing insurance solutions for the past decade.
                            Our insurance services include life and Health insurance that provide economic protection, kids education, retirement and transfer of wealth
                                 </p>
                            <div><p>FinMart extended services to offer
                                mortgage solutions to its customers. </p></div>
                            <div class="discover-button clearfix">
                                <a href="#" class="button button-stripe">Learn More</a>
                                {/* <!-- <a href="#" class="button button-download">
                                <span class="button-download-title">Download for</span>
                                <span class="button-download-subtitle">Apple iOS</span>
                            </a> -->
                            <!-- <a href="#" class="button button-download android">
                                <span class="button-download-title">Download for</span>
                                <span class="button-download-subtitle">Android</span>
                            </a> --> */}
                            </div>
                        </div>
                        <div class="discover-img">
                            <div class="discover-img-inside"><img src="images/about-img.jpg" height="486" width="534" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div class="video clearfix">
                    <div class="wrap">
                        <div class="video-title">Explore. Finmart</div>
                        <div class="video-subtitle">Just try it for yourself</div>
                        <div class="video-block">
                            <iframe src="//player.vimeo.com/video/106575373?portrait=0" width="814" height="458"
                                frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                        </div>
                        <div class="video-share-wrapper clearfix">
                            <ul class="social-list clearfix">
                                <li class="video-share-title">Share it with your friends:</li>
                                <li><a href="#" class="social-twitter">via <strong>Twitter</strong></a></li>
                                <li><a href="#" class="social-facebook">via <strong>Facebook</strong></a></li>
                                <li><a href="#" class="social-google">via <strong>Google+</strong></a></li>
                            </ul>
                        </div>
                    </div>
                </div> */}
                <div class="comments clearfix" id="Testimonials">
                    <div class="wrap">
                        <div class="tab">
                            <div class="box visible">
                                <h4>People talking about <a href="#">FinMart</a></h4>
                                <p>Join our #FinMart. Meet up in London this Saturday! Spread the word and
                                join us for fun.</p>
                            </div>
                            <div class="box">
                                <h4>Users loving <a href="#">#FinMart</a></h4>
                                <p>Joining this weekend's #FinMart fun!  bring your dogs, we can make it a
                                fun walk!</p>
                            </div>
                            <div class="box">
                                <h4>Folks enjoying <a href="#">#FinMart</a></h4>
                                <p>Nothing to fear, the new #FinMart has my tiny street covered, so cool and such a
                                powerful app.</p>
                            </div>
                            <ul class="tabs">
                                <li class="active"></li>
                                <li></li>
                                <li></li>
                            </ul>
                            <a href="#" class="tab-prev"></a>
                            <a href="#" class="tab-next"></a>
                        </div>
                    </div>
                </div>
                <div class="simple clearfix" id="Services">
                    <div class="wrap">
                        <div class="simple-content">
                            <h3>Our Services</h3>
                            <ul>
                                <li class="clearfix"><img src="upload/ico1.png" height="32" width="32" alt="" /><span>Mortgage Loans</span></li>
                                <li class="clearfix"><img src="upload/ico2.png" height="32" width="32" alt="" /><span>Health Insurance</span></li>
                                <li class="clearfix"><img src="upload/ico3.png" height="32" width="32" alt="" /><span>Life Insurance</span></li>
                            </ul>
                        </div>
                        <div class="simple-img">
                            <img src="images/about-img.jpg" height="508" width="587" alt="" />
                        </div>
                    </div>
                </div>

                <div class="newsletter clearfix">
                    <div class="wrap">
                        <div class="newsletter-title">our newsletter</div>
                        <div class="newsletter-form clearfix">
                            <form action="">
                                <input type="email" placeholder="john@doe.com" class="input-text" />
                                <input type="button" class="button" value="Subscribe" />
                            </form>
                        </div>
                        <p>If you want to recieve monthly updates from us just pop your email in the box. We think that spam
                        is for jerks. And we are no jerks.</p>
                    </div>
                </div>
            </div>
            <footer>
                <div class="wrap">
                    <p>&copy; 2014 <strong>FinMart</strong>, All Rights Reserved</p>
                </div>
            </footer>
        </div>

    )
}
