import React from 'react'

export default function Refinance (props) {
  const { handleRefinanceFormDataChange, refinanceFormData } = props
  return (
    <div>
      <fieldset>
        <h2> Purchase </h2>
        <div class='fieldset-content'>
          <div class='form-row'>
            <label class='form-label'> Name </label>
            <div class='form-flex'>
              <div class='form-group'>
                <input
                  type='text'
                  value={refinanceFormData.name}
                  onChange={e =>
                    handleRefinanceFormDataChange('name', e.target.value)
                  }
                />
                <span class='text-input'> Name </span>
              </div>
            </div>
          </div>
          <div class='form-flex'>
            <div class='form-group'>
              <label class='form-label'> Email </label>
              <input
                type='email'
                value={refinanceFormData.email}
                onChange={e =>
                  handleRefinanceFormDataChange('email', e.target.value)
                }
              />
              <span class='text-input'>
                Example: <span> Jeff @gmail.com </span>
              </span>
            </div>
          </div>
          <div class='form-flex'>
            <div class='form-group'>
              <label class='form-label'> Estimated Value </label>
              <input
                value={refinanceFormData.estimatedValue}
                onChange={e =>
                  handleRefinanceFormDataChange(
                    'estimatedValue',
                    e.target.value
                  )
                }
              />
              <span class='text-input'> Estimated Value </span>
            </div>
            <div class='form-group'>
              <label class='form-label'> Loan Amount Requested</label>
              <input
                value={refinanceFormData.loanAmountRequested}
                onChange={e =>
                  handleRefinanceFormDataChange(
                    'loanAmountRequested',
                    e.target.value
                  )
                }
              />
              <span class='text-input'> Loan Amount Requested </span>
            </div>
          </div>
          <div class='form-flex'>
            <div class='form-group'>
              <label class='form-label'> Zip Code </label>
              <input
                value={refinanceFormData.zipCode}
                onChange={e =>
                  handleRefinanceFormDataChange('zipCode', e.target.value)
                }
              />
              <span class='text-input'> Loan Amount Required </span>
            </div>
          </div>
          <div class='form-flex'>
            <div class='form-group'>
              <label class='form-label'> Quoted Credit Score </label>
              <input
                value={refinanceFormData.quotedCreditScore}
                onChange={e =>
                  handleRefinanceFormDataChange(
                    'quotedCreditScore',
                    e.target.value
                  )
                }
              />
              <span class='text-input'> Quoted Credit Score </span>
            </div>
          </div>
          <div class='form-row'>
            <div class='form-flex'>
              <div class='form-group'>
                <label class='form-label'> Property Type </label>
                <div class='select'>
                  <select
                    value={refinanceFormData.propertyType || 'Choose an option'}
                    onChange={e =>
                      handleRefinanceFormDataChange(
                        'propertyType',
                        e.target.value
                      )
                    }
                  >
                    <option selected disabled>
                      Choose an option
                    </option>
                    <option value='Primary'> Primary </option>
                    <option value='Investment'> Investment </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class='form-row'>
            <div class='form-flex'>
              <div class='form-group'>
                <label class='form-label'> Reason For Refinance</label>
                <div class='select'>
                  <select
                    value={refinanceFormData.reasonForRefinance || 'Choose an option'}
                    onChange={e =>
                      handleRefinanceFormDataChange(
                        'reasonForRefinance',
                        e.target.value
                      )
                    }
                  >
                    <option selected disabled>
                      Choose an option
                    </option>
                    <option value='Conventional'> Cash Out </option>
                    <option value='FHA'> Lower Rate Or Term </option>
                    <option value='VA'> Other? </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class='form-flex'>
            <div class='form-group'>
              <label class='form-label'> Amortization Period </label>
              <input
                value={refinanceFormData.amortizationPeriod}
                onChange={e =>
                  handleRefinanceFormDataChange(
                    'amortizationPeriod',
                    e.target.value
                  )
                }
              />
              <span class='text-input'> Amortization Period(Years) </span>
            </div>
          </div>
        </div>
        <div class='form-row'>
          <div class='form-flex'>
            <div class='form-group'>
              <label class='form-label'> Type Of Loan </label>
              <div class='select'>
                <select
                  value={refinanceFormData.typeOfLoan || 'Choose an option'}
                  onChange={e =>
                    handleRefinanceFormDataChange('typeOfLoan', e.target.value)
                  }
                >
                  <option selected disabled>
                    Choose an option
                  </option>
                  <option value='Conventional'> Conventional </option>
                  <option value='FHA'> FHA </option>
                  <option value='VA'> VA </option>
                  <option value='USDA'> USDA </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class='form-row'>
          <div class='form-flex'>
            <div class='form-group'>
              <input
                type='checkbox'
                checked={refinanceFormData.closingCostAssistance}
                onChange={e => {
                  handleRefinanceFormDataChange(
                    'closingCostAssistance',
                    e.target.checked
                  )
                }}
              />
            <label>Closing Cost Assistance ?</label>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  )
}
