import React from 'react';
import Home from './home'
import Form from './form'
import Estimate from './estimate'
import { Router } from '@reach/router'

function App() {
  return (
    <div className="App">
      <Router>
        <Home path="/" />
        <Form path="/form" />
        <Estimate path="/estimate" />
      </Router>
    </div>
  );
}

export default App;
