import React from 'react'

export default function LoanProperty(props) {
    const { loanPropertyFormData, handleOnChangePropertyInfo, backButtonHandle } = props;
    return (
        <fieldset>
            <h2>Loan Property</h2>
            <p class="desc">Please enter your information and proceed to next step so we can build your account</p>
            <div class="back-button" onClick={backButtonHandle}>Back</div>
            <div class="fieldset-content">
                <div class="form-row">
                    <div class="form-flex">
                        <div class="form-group" >
                            <label class="form-label">Residence Type</label>
                            <div class="select">
                                <select value={loanPropertyFormData.residenceType || "Choose an option"}
                                onChange={e => handleOnChangePropertyInfo("residenceType", e.target.value) }>
                                    <option selected disabled>Choose an option</option>
                                    <option value="Town Home">Town Home</option>
                                    <option value="Single family residence">Single family residence</option>
                                    <option value="Condo">Condo</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-label">Address</label>
                    <input type="text" value={loanPropertyFormData.address}
                    onChange={e => handleOnChangePropertyInfo("address", e.target.value)}/>
                    <span class="text-input">Street Address</span>
                </div>
                <div class="form-row">
                    <div class="form-row">
                        <div class="form-flex">
                            <div class="form-group">
                                <input type="text" value={loanPropertyFormData.city}
                                onChange={e => handleOnChangePropertyInfo("city", e.target.value)}/>
                                <span class="text-input">City</span>
                            </div>
                            <div class="form-group">
                                <input type="text" value={loanPropertyFormData.state}
                                onChange={e => handleOnChangePropertyInfo("state", e.target.value)}/>
                                <span class="text-input">State</span>
                            </div>
                            <div class="form-group">
                                <input type="text" value={loanPropertyFormData.zipCode}
                                onChange={e => handleOnChangePropertyInfo("zipCode", e.target.value)}/>
                                <span class="text-input">Zip Code</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <label class="form-label">Loan Information</label>
                    <div class="form-flex">
                        <div class="form-group">
                            <label class="form-label">Terms (in Years)</label>
                            <input type="text" value={loanPropertyFormData.LITerms}
                            onChange={e => handleOnChangePropertyInfo("LITerms", e.target.value)} />
                            <span class="text-input">Terms</span>
                        </div>
                        <div class="form-group">
                            <label class="form-label">Desired Rate</label>
                            <input type="text" value={loanPropertyFormData.desiredRate}
                            onChange={e => handleOnChangePropertyInfo("desiredRate", e.target.value)} />
                            <span class="text-input">Desired Rate</span>
                        </div>
                        <div class="form-group">
                            <label class="form-label">Loan Purpose</label>
                            <div class="select">
                                <select value={loanPropertyFormData.loanPurpose || "Choose an option"}
                                onChange={e => handleOnChangePropertyInfo("loanPurpose", e.target.value)}>
                                    <option selected disabled>Choose an option</option>
                                    <option value="Purchase">Purchase</option>
                                    <option value="Refinance">Refinance</option>
                                    <option value="Refinance - Cash Out">Refinance - Cash Out</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="form-label">Loan Amount</label>
                            <input type="text" value={loanPropertyFormData.loanAmount || ""}
                            onChange={e => handleOnChangePropertyInfo("loanAmount", e.target.value)}/>
                            <span class="text-input">Amount</span>
                        </div>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-flex">
                        <div class="form-group">
                            <label class="form-label">Year Acquired</label>
                            <input type="text" value={loanPropertyFormData.yearAcquired || ""}
                            onChange={e => handleOnChangePropertyInfo("yearAcquired", e.target.value)}/>
                            <span class="text-input">Terms</span>
                        </div>
                        <div class="form-group">
                            <label class="form-label">Original Cost</label>
                            <input type="text" value={loanPropertyFormData.originalCost || ""}
                            onChange={e => handleOnChangePropertyInfo("originalCost", e.target.value)}/>
                            <span class="text-input">Rate</span>
                        </div>
                        <div class="form-group">
                            <label class="form-label">Current Property Value</label>
                            <input type="text" value={loanPropertyFormData.currentPropertyValue || ""}
                            onChange={e => handleOnChangePropertyInfo("currentPropertyValue", e.target.value)}/>
                            <span class="text-input">Rate</span>
                        </div>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-flex">
                        <div class="form-group">
                            <label class="form-label">Purpose of Refinance</label>
                            <input type="text" value={loanPropertyFormData.purposeOfRefinance || ""}
                            onChange={e => handleOnChangePropertyInfo("purposeOfRefinance", e.target.value)}/>
                            <span class="text-input">Terms</span>
                        </div>
                        <div class="form-group">
                            <label class="form-label">Amount Existing liens</label>
                            <input type="text" value={loanPropertyFormData.amountExistingItems || ""}
                            onChange={e => handleOnChangePropertyInfo("amountExistingItems", e.target.value)}/>
                            <span class="text-input">Rate</span>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
    )
}
