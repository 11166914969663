import React, { useState } from 'react'
import ContactInfo from './contact-info'
import LoanProperty from './loan-property'
import IncomeInfo from './income-info'
import { navigate } from '@reach/router'
import {
  contactInfoFormDataInitialState,
  propertyInfoFormDataInitialState,
  incomeInfoFormDataInitialState,
  steps,
  contactInfoAbbreviation,
  loanPropertyAbbreviation,
  incomeAbbreviation
} from './initial-states.js'

export default function Form() {
  const EMAIL_TO = "madhu.chukkapalli@gmail.com"
  const handleFillFormOnClick = () => {
    navigate('/form')
  }

  const [level, setLevel] = useState(0)

  const [contactInfoFormData, setContactInfoFormData] = useState(
    contactInfoFormDataInitialState
  )
  const [loanPropertyFormData, setLoanPropertyFormData] = useState(
    propertyInfoFormDataInitialState
  )
  const [incomeInfoFormData, setIncomeInfoFormData] = useState(
    incomeInfoFormDataInitialState
  )

  const handleNext = () => {
    if (level < 11) {
      setLevel(level + 1)
    }
    consoleData()
  }

  const handleBack = () => {
    if (level > 0) {
      setLevel(level - 1)
    }
  }

  const consoleData = () => {
    switch (level) {
    }
  }

  const handleOnChangeContactInfo = (fieldName, value) => {
    let data = { ...contactInfoFormData }
    data[fieldName] = value
    setContactInfoFormData(data)
  }

  const handleOnChangePropertyInfo = (fieldName, value) => {
    let data = { ...loanPropertyFormData }
    data[fieldName] = value
    setLoanPropertyFormData(data)
  }

  const handleOnChangeIncomeInfo = (fieldName, value) => {
    let data = { ...incomeInfoFormData }
    data[fieldName] = value
    setIncomeInfoFormData(data)
  }

  const getSubmitBody = () => {
    const NEW_LINE = '%0D%0A'
    let body = 'CONTACT INFO ' + NEW_LINE
    const contactInfoFields = Object.keys(contactInfoAbbreviation)
    for (let key of contactInfoFields) {
      body +=
        contactInfoAbbreviation[key] + ': ' + (contactInfoFormData[key] || '') + NEW_LINE;
    }
    body += NEW_LINE + "LOAN / PROPERTY INFO" + NEW_LINE;
    const loanInfoFields = Object.keys(loanPropertyAbbreviation)
    for (let key of loanInfoFields) {
      body += loanPropertyAbbreviation[key] + ": " + (loanPropertyFormData[key] || '') + NEW_LINE
    }
    body += NEW_LINE + "INCOME INFO" + NEW_LINE;
    const incomeInfoFields = Object.keys(incomeAbbreviation)
    for (let key of incomeInfoFields) {
      body += incomeAbbreviation[key] + ": " + (incomeInfoFormData[key] || '') + NEW_LINE
    }
    window.location.href = `mailto:${EMAIL_TO}?subject=Application Form&body=` + body;
  }

  const handleSubmit = () => {
    getSubmitBody()
  }

  return (
    <div class='main'>
      <header>
        <div class='menu-header'>
          <div class='menu-header-wrapper'>
            <div class='left-logo'>
              <img
                src='upload/logo.png'
                height='50'
                width='50'
                alt=''
                class='logo-img'
              />
            </div>
            <div class='right-menu'>
              <ul>
                <li><a href="/">Home</a></li>
                <li><a href="/#About">About us</a></li>
                <li><a href="/#Services">Services</a></li>
                <li><a href="/estimate">Estimate</a></li>
                <li><a href="/#Testimonials">Testimonials</a></li>
                <li><a onClick={handleFillFormOnClick} class="button button-stripe">Apply Now</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class='wrap'>
          <div class='header-wrapper'>
            <h2>Mortgage Loan Application</h2>
          </div>
        </div>
      </header>
      <div class='container form-container'>
        <div className='sidebar'>
          <div class='steps clearfix'>
            <ul role='tablist'>
              {steps.map((item, index) => {
                return (
                  <li
                    role='tab'
                    className={index > level ? '' : 'current'}
                    aria-disabled='false'
                    onClick={() => setLevel(index)}
                  >
                    <a id='steps-uid-0-t-0' aria-controls='steps-uid-0-p-0'>
                      <div class='title'>
                        <span class='step-number'>{index + 1}</span>
                        <span class='step-text'>{item}</span>
                      </div>
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        <div className='content'>
          {level === 0 && (
            <ContactInfo
              contactInfoFormData={contactInfoFormData}
              handleOnChangeContactInfo={handleOnChangeContactInfo}
              backButtonHandle={handleBack}
            />
          )}
          {level === 1 && (
            <LoanProperty
              loanPropertyFormData={loanPropertyFormData}
              handleOnChangePropertyInfo={handleOnChangePropertyInfo}
              backButtonHandle={handleBack}
            />
          )}
          {level === 2 && (
            <IncomeInfo
              incomeInfoFormData={incomeInfoFormData}
              handleOnChangeIncomeInfo={handleOnChangeIncomeInfo}
              backButtonHandle={handleBack}
            />
          )}
          <button
            className='button btn-stripe m-10'
            onClick={level == 2 ? handleSubmit : handleNext}
          >
            {level == 2 ? 'Submit' : 'Next'}
          </button>
        </div>
      </div>
      <footer>
        <div class='wrap'>
          <p>
            &copy; 2020 <strong>FinMart</strong>, All Rights Reserved
          </p>
        </div>
      </footer>
    </div>
  )
}
