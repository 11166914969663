import React from 'react'

export default function IncomeInfo(props) {
    const { incomeInfoFormData, 
              handleOnChangeIncomeInfo,
     } = props;
    return (
      <fieldset>
          <h2>Income Information</h2>
          <div class="fieldset-content">
          <div class="form-row">
              <div class="form-flex">
                <div class="form-group">
                    <label class="form-label">Income period</label>
                    <div class="select">
                        <select value={incomeInfoFormData.incomePeriodType || "Choose an option"}
                          onChange={e => handleOnChangeIncomeInfo("incomePeriodType", e.target.value)}>
                            <option selected disabled>Choose an option</option>
                            <option value="Hourly">Hourly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Annual">Annual</option>
                        </select>
                    </div>
                </div>
              </div>
          </div>
          <div class="form-row">
                  <div class="form-flex">
                    <div class="form-group">
                        <label class="form-label">Gross Income Breakdown</label>
                    </div>
                  </div>
          </div>
          <div class="form-row">
              <div class="form-flex">
                <div class="form-group">
                  <label class="form-label">Base Income</label>
                  <input type="text" value={incomeInfoFormData.baseIncome}
                          onChange={e => handleOnChangeIncomeInfo("baseIncome", e.target.value)}/>
                  <span class="text-input">Base Income</span>
                </div>
                <div class="form-group">
                  <label class="form-label">Overtime</label>
                  <input type="text" value={incomeInfoFormData.overTime}
                          onChange={e => handleOnChangeIncomeInfo("overTime", e.target.value)}/>
                  <span class="text-input">Overtime</span>
                </div>
                <div class="form-group">
                  <label class="form-label">Bonuses</label>
                  <input type="text" value={incomeInfoFormData.bonuses}
                          onChange={e => handleOnChangeIncomeInfo("bonuses", e.target.value)}/>
                  <span class="text-input">Bonuses</span>
                </div>
              </div>
          </div>
          <div class="form-row">
              <div class="form-flex">
                <div class="form-group">
                  <label class="form-label">Commissions</label>
                  <input type="text" value={incomeInfoFormData.commissions}
                          onChange={e => handleOnChangeIncomeInfo("commissions", e.target.value)}/>
                  <span class="text-input">Commissions</span>
                </div>
                <div class="form-group">
                  <label class="form-label">Dividends/Interest</label>
                  <input type="text" value={incomeInfoFormData.interest}
                          onChange={e => handleOnChangeIncomeInfo("interest", e.target.value)} />
                  <span class="text-input">Dividends/Interest</span>
                </div>
                <div class="form-group">
                  <label class="form-label">Net Rental Income</label>
                  <input type="text" value={incomeInfoFormData.netRentalIncome}
                          onChange={e => handleOnChangeIncomeInfo("netRentalIncome", e.target.value)} />
                  <span class="text-input">Net Rental Income</span>
                </div>
              </div>
          </div>
          <div class="form-row">
              <div class="form-flex">
                <div class="form-group">
                  <label class="form-label">Other Income</label>
                  <input type="text" value={incomeInfoFormData.otherIncome}
                          onChange={e => handleOnChangeIncomeInfo("otherIncome", e.target.value)} />
                  <span class="text-input">Other Income</span>
                </div>
              </div>
          </div>

          <p class="desc">*Self Employed Borrower(s) may be required to provide additional such as tax
          returns and financial statements</p>
          </div>
      </fieldset>
    )
}
