import React from 'react'

export default function Purchase (props) {
  const { handlePurchaseFormDataChange, purchaseFormData } = props
  return (
    <div>
      <fieldset>
        <h2> Purchase </h2>
        <div class='fieldset-content'>
          <div class='form-row'>
            <label class='form-label'> Name </label>
            <div class='form-flex'>
              <div class='form-group'>
                <input
                  type='text'
                  value={purchaseFormData.name}
                  onChange={e =>
                    handlePurchaseFormDataChange('name', e.target.value)
                  }
                />
                <span class='text-input'>Name</span>
              </div>
            </div>
          </div>
          <div class='form-flex'>
            <div class='form-group'>
              <label class='form-label'> Email </label>
              <input
                type='email'
                value={purchaseFormData.email}
                onChange={e =>
                  handlePurchaseFormDataChange('email', e.target.value)
                }
              />
              <span class='text-input'>
                Example: <span> Jeff @gmail.com </span>
              </span>
            </div>
          </div>
          <div class='form-flex'>
            <div class='form-group'>
              <label class='form-label'> Purchase Price</label>
              <input
                value={purchaseFormData.purchasePrice}
                onChange={e =>
                  handlePurchaseFormDataChange('purchasePrice', e.target.value)
                }
              />
              <span class='text-input'>Purchase Price</span>
            </div>
            <div class='form-group'>
              <label class='form-label'>Down Payment</label>
              <input
                value={purchaseFormData.downPayment}
                onChange={e =>
                  handlePurchaseFormDataChange('downPayment', e.target.value)
                }
              />
              <span class='text-input'> Down Payment</span>
            </div>
          </div>
          <div class='form-flex'>
            <div class='form-group'>
              <label class='form-label'> Zip Code</label>
              <input
                value={purchaseFormData.zipCode}
                onChange={e =>
                  handlePurchaseFormDataChange('zipCode', e.target.value)
                }
              />
              <span class='text-input'> Loan Amount Required</span>
            </div>
          </div>
          <div class='form-flex'>
            <div class='form-group'>
              <label class='form-label'>Quoted Credit Score</label>
              <input
                value={purchaseFormData.quotedCreditScore}
                onChange={e =>
                  handlePurchaseFormDataChange(
                    'quotedCreditScore',
                    e.target.value
                  )
                }
              />
              <span class='text-input'>Quoted Credit Score</span>
            </div>
          </div>
          <div class='form-row'>
            <div class='form-flex'>
              <div class='form-group'>
                <label class='form-label'>Property Type</label>
                <div class='select'>
                  <select
                    value={purchaseFormData.propertyType || 'Choose an option'}
                    onChange={e =>
                      handlePurchaseFormDataChange(
                        'propertyType',
                        e.target.value
                      )
                    }
                  >
                    <option selected disabled>
                      Choose an option
                    </option>
                    <option value='Primary'>Primary</option>
                    <option value='Investment'>Investment</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class='form-flex'>
            <div class='form-group'>
              <label class='form-label'>Amortization Period</label>
              <input
                value={purchaseFormData.amortizationPeriod}
                onChange={e =>
                  handlePurchaseFormDataChange(
                    'amortizationPeriod',
                    e.target.value
                  )
                }
              />
              <span class='text-input'>Amortization Period ( Years )</span>
            </div>
          </div>
        </div>
        <div class='form-row'>
          <div class='form-flex'>
            <div class='form-group'>
              <label class='form-label'>Type Of Loan</label>
              <div class='select'>
                <select
                  value={purchaseFormData.typeOfLoan || 'Choose an option'}
                  onChange={e =>
                    handlePurchaseFormDataChange('typeOfLoan', e.target.value)
                  }
                >
                  <option selected disabled>
                    Choose an option
                  </option>
                  <option value='Conventional'>Conventional</option>
                  <option value='FHA'>FHA</option>
                  <option value='VA'>VA</option>
                  <option value='USDA'>USDA</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class='form-row'>
          <div class='form-flex'>
            <div class='form-group'>
              <input
                type='checkbox'
                checked={purchaseFormData.closingCostAssistance}
                onChange={e => {
                  handlePurchaseFormDataChange(
                    'closingCostAssistance',
                    e.target.checked
                  )
                }}
              />
              Closing Cost Assistance?
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  )
}
